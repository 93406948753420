.pageloader {
  background-color: rgba(black, .9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  &__notification-box {
    background-color: var(--alpha5);
    border-radius: 10px;
    padding: 1em;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__img {
    max-width: 200px;

    &--submitted {
      animation-name: rotateY;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    &--error {
      animation: rotateZ 2s forwards;
    }
    &--success {
    }
  }
  &__img2 {
  position: absolute;
  max-width: 200px;
  animation-name: rotateY2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  }
}

@keyframes rotateY {
  0% {transform: rotateY(0deg)}
  100% {transform: rotateY(360deg)}
}

@keyframes rotateY2 {
  0% {transform: rotateY(90deg)}
  100% {transform: rotateY(450deg)}
}

@keyframes rotateZ {
  0% {transform: rotateZ(0deg)}
  100% {transform: rotateZ(180deg)}
}

.gallery {

  &__lightbox {
    margin-left: calc(-1 * var(--innerthorax-padding-horizontal));
    margin-right: calc(-1 * var(--innerthorax-padding-horizontal));
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__image {
  }

  &__arrow {
    position: absolute;
    color: var(--alpha5);
    margin-left: 0;
    font-size: calc(40px + .05 * var(--innerthorax-width));
    opacity: .05;
    &:hover {
      opacity: .9;
      cursor: pointer;
    }
    &:active {
      color: var(--alpha3);
    }

    &--left {
      left: 2vw;
    }
    &--right {
      right: 2vw;
    }
  }

  &__indicator-container {
    position: absolute;
    bottom: 2vw;
  }
  &__indicator {
    background: var(--beta5);
    height: calc(.05 * var(--innerthorax-width));
    width: calc(.05 * var(--innerthorax-width));
    max-width: 20px;
    max-height: 20px;
    padding: 0;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    &--selected {
      background: var(--alpha5);
    }
  }


  ul {
    position: relative;
    margin: 0;
    list-style: none;
  }
  li {
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }
  li.hidden {
    position: absolute;
    bottom: 0;
    opacity: 0;
  }
}

nav {
  align-self: flex-start;

  #menuButton {
    font-size: calc(20px + 3vw);
    // font-size: calc(12px + (30 - 12) * (100vw - 270px) / (920 - 270));
    margin-left: 2vw;
  }

  ul {
    display: none;
    background: white;
    position: absolute;
    // padding: 0;
    // font-size: 2em;
    list-style-type: none;
  	z-index: 3;
    box-shadow: 2px 2px 1px black;
  }
  li {
    background: var(--alpha5);
  }
  li:hover {
    background: var(--alpha3);
    box-shadow: 0 0 15px 2px var(--beta5);
  }
  a {
    display: block;
    color: white;
    padding: .5em .3em;

    &:hover {
      text-decoration: none;
    }
  }
  .currentpage {
    color: var(--beta5);
    background: var(--alpha3);
    font-weight: bold;
  }
  .show {
    display: block;
  }
}

@media all and (min-width: 920px) {
  nav {
    width: var(--innerthorax-width);
    align-self: center;

    #menuButton {
      display: none;
    }

    ul {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      font-size: initial;
      box-shadow: none;
      z-index: auto;
      display: flex;
      justify-content: center;
    }

    li {
      background: var(--alpha5);
      border: .5px solid gray;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      flex-grow: 1;
    }

    a {
      text-align: center;
      font-size: calc(.024 * var(--innerthorax-width));
    }
  }
}

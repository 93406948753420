.footer {
  background-color: var(--alpha3);
  width: 100%;
  clear: both;
  // border-top: 5px solid var(--beta5);
  box-shadow: 0px -5px 20px black;
  padding: 0 2em;
  min-height: 2em;
  & * {
    color: white;
  }
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    &.lplinks {
      font-size: .75rem;
      a {
        margin-left: .5em;
        margin-right: .5em
      }
    }
  }
}

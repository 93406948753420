.actnow {
  margin: 3em auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__top-line, &__top-line a {
    background: rgba(white, .9);
    color: hsla(30, 100%, 50%, 1);
    font-weight: bold;
    animation-name: pulse;
    animation-duration: .75s;
    animation-direction: alternate;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &__bottom-line {
    background: rgba(white, .9);
  }
}



@keyframes pulse {
  0% {transform: scale(1)}
  60% {transform: scale(1)}
  80% {transform: scale(1.5)}
  100% {transform: scale(1.2)}
  // 100% {-webkit-transform: scale(1.5);transform: scale(1.5)}
}

:root {
  --alpha9: ;
  --alpha8: ;
  --alpha7: ;
  --alpha6: ;
  --alpha5: hsla(30, 100%, 50%, 1);
  --alpha4: ;
  --alpha3: hsla(30, 100%, 40%, 1);
  --alpha2: ;
  --alpha1: hsla(30, 100%, 30%, 1);
  --beta9: hsla(120, 100%, 90%, 1);
  --beta8: ;
  --beta7: ;
  --beta6: ;
  --beta5: hsla(120, 100%, 20%, 1);
  --beta4: ;
  --beta3: hsla(120, 100%, 5%, 1);
  --beta2: ;
  --beta1: ;
  --background-image: url("/img/transparent-orange100.svg");
  --signature-font: "Catamaran", sans-serif;
  --contents-width: calc(100vw - 17px);
  --thorax-width: 100vw;
  --innerthorax-width: var(--contents-width);
  --innerthorax-padding-horizontal: calc(.04 * var(--innerthorax-width));
  font-size: calc(10pt + 0.01 * var(--innerthorax-width));
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  text-align: justify;
  font-family: "KoHo", sans-serif;
}

h2, h3, h4, h5, h6 {
  color: var(--beta5);
  margin-top: 2em;
  margin-bottom: 0.5em;
  text-align: left;
}

h2 {
  font-size: calc(18px + 12 * (100vw - 300px) / 620);
}
h2.center {
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--beta5);
}
a:hover {
  text-decoration: underline;
}

p + p,
p + img, img + p,
p + picture, picture + p,
p + .imgcontainer, .imgcontainer + p,
section + *, * + section {
  margin-top: 1em;
}

section:first-of-type {
  margin-top: 1em;
}

i + *, * + i {
  margin-left: 0.5em;
}

.buttoncontainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn {
  background: var(--beta5);
  color: white;
  padding: 0.1em 0.5em;
  cursor: pointer;
}
.btn__icon {
  color: rgba(255, 255, 255, 0.75);
}
.btn:hover {
  box-shadow: 0 0 15px 2px var(--beta5);
}
.btn:active {
  background: var(--beta3);
}

.imgcontainer {
  display: flex;
  justify-content: center;
}

img {
  width: 100%;
}

.map, section > .imgcontainer {
  border: 0;
  margin-left: calc(-1 * var(--innerthorax-padding-horizontal));
  margin-right: calc(-1 * var(--innerthorax-padding-horizontal));
  width: var(--innerthorax-width);
  height: calc(0.618 * var(--innerthorax-width));
}

.lp__title {
  margin-top: 1em;
}

.thorax {
  background-image: var(--background-image);
  background-size: contain;
  margin-top: 2em;
  width: 100%;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerthorax {
  background: white;
  background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)), var(--background-image);
  background-size: contain;
  width: var(--innerthorax-width);
  margin-bottom: 2em;
}
.innerthorax > img {
  margin: 0;
}
.innerthorax > img + *, .innerthorax > * + img {
  margin-top: 2em;
}
.innerthorax > *, .innerthorax ul {
  margin-left: var(--innerthorax-padding-horizontal);
  margin-right: var(--innerthorax-padding-horizontal);
}
.innerthorax ul {
  margin-bottom: 1em;
}

:root {
  --title-width: 100%;
  --titlecontents-width: var(--contents-width);
}

h1 {
  text-align: center;
  color: var(--alpha5);
  font-family: var(--signature-font);
  font-size: calc(0.14 * var(--titlecontents-width)); /*Changed in psico*/
}

.title {
  margin-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title img {
  width: calc(0.33 * var(--titlecontents-width));
}
.title__subtitle {
  color: var(--beta5);
  font-size: calc(0.05 * var(--titlecontents-width));
}

@media all and (min-width: 800px) {
  :root {
    --titlecontents-width: 700px;
  }
}
#nav-languages {
  width: var(--innerthorax-width);
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
#nav-languages li {
  background: var(--alpha5);
  border: 0.5px solid gray;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
#nav-languages li:hover {
  background: var(--alpha3);
  box-shadow: 0 0 15px 2px var(--beta5);
}
#nav-languages a {
  display: block;
  color: white;
  padding: 0.5em 0.3em;
}
#nav-languages a:hover {
  text-decoration: none;
}

nav {
  align-self: flex-start;
}
nav #menuButton {
  font-size: calc(20px + 3vw);
  margin-left: 2vw;
}
nav ul {
  display: none;
  background: white;
  position: absolute;
  list-style-type: none;
  z-index: 3;
  box-shadow: 2px 2px 1px black;
}
nav li {
  background: var(--alpha5);
}
nav li:hover {
  background: var(--alpha3);
  box-shadow: 0 0 15px 2px var(--beta5);
}
nav a {
  display: block;
  color: white;
  padding: 0.5em 0.3em;
}
nav a:hover {
  text-decoration: none;
}
nav .currentpage {
  color: var(--beta5);
  background: var(--alpha3);
  font-weight: bold;
}
nav .show {
  display: block;
}

@media all and (min-width: 920px) {
  nav {
    width: var(--innerthorax-width);
    align-self: center;
  }
  nav #menuButton {
    display: none;
  }
  nav ul {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    font-size: initial;
    box-shadow: none;
    z-index: auto;
    display: flex;
    justify-content: center;
  }
  nav li {
    background: var(--alpha5);
    border: 0.5px solid gray;
    border-bottom: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    flex-grow: 1;
  }
  nav a {
    text-align: center;
    font-size: calc(0.024 * var(--innerthorax-width));
  }
}
.footer {
  background-color: var(--alpha3);
  width: 100%;
  clear: both;
  box-shadow: 0px -5px 20px black;
  padding: 0 2em;
  min-height: 2em;
}
.footer * {
  color: white;
}
.footer p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer p.lplinks {
  font-size: 0.75rem;
}
.footer p.lplinks a {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.actnow {
  margin: 3em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.actnow__top-line, .actnow__top-line a {
  background: rgba(255, 255, 255, 0.9);
  color: hsl(30, 100%, 50%);
  font-weight: bold;
  animation-name: pulse;
  animation-duration: 0.75s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.actnow__bottom-line {
  background: rgba(255, 255, 255, 0.9);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2);
  }
}
form {
  --radiocheck-size-outer: 18px;
  --radiocheck-size-outer-border: 1px;
  --radiocheck-size-inner: 14px;
  --radiocheck-size-inner-border: 0px;
  --radiocheck-size-difference: calc((var(--radiocheck-size-outer) + 2 * var(--radiocheck-size-outer-border)) - (var(--radiocheck-size-inner) + 2 * var(--radiocheck-size-inner-border)));
  line-height: 1;
}
form section {
  display: flex;
  margin: 0.5em 0;
}
form section > :first-child {
  background: var(--beta5);
  color: rgba(255, 255, 255, 0.75);
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 2em;
}
form section > :last-child {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 0 10px 10px 0;
  padding: 1em;
  flex-grow: 1;
  border: solid 1px var(--beta5);
}
form .row {
  display: flex;
  align-items: center;
  /*flex-wrap: wrap;*/
}
form .row + .row {
  margin-top: 1em;
}
form .row.textarea {
  flex-direction: column;
  align-items: flex-start;
}
form .row.space-horizontally {
  justify-content: space-around;
}
form .footnote {
  border-top: darkgray solid 1px;
  font-size: 0.8em;
  margin-top: 1em;
}
form .buttoncontainer--form {
  justify-content: flex-end;
}

label + *, * + label { /* Change this on teatroterapia.css*/
  margin-left: 0.5em;
}

fieldset {
  border: 0;
}

fieldset + fieldset {
  margin-top: 2em;
}

input, textarea, select, button {
  background: var(--beta9);
  border: 0;
  outline: 0;
  resize: vertical;
  font-size: var(--font-size);
  border-radius: 5px;
  padding-left: 0.5em;
}

textarea {
  height: 7em;
  margin-left: 0;
  align-self: stretch;
}

button {
  border: initial;
}

/* Radio and Checkbox Inputs */
input[type=radio], input[type=checkbox] {
  opacity: 0.1;
  width: 0;
}

[type=radio] + label, [type=checkbox] + label {
  position: relative;
  padding-left: calc(var(--radiocheck-size-outer) + 0.5em);
  text-align: left;
}

[type=radio] + label::before, [type=checkbox] + label::before {
  content: "";
  text-align: center;
  display: inline-block;
  height: var(--radiocheck-size-outer);
  width: var(--radiocheck-size-outer);
  background: var(--beta9);
  border: var(--radiocheck-size-outer-border) solid var(--beta9);
}

[type=radio] + label::before, [type=radio] + label::after {
  border-radius: 50%;
}

[type=checkbox] + label::before, [type=checkbox] + label::after {
  border-radius: 0;
}

[type=radio] + label::after, [type=checkbox] + label::after {
  content: "";
  display: inline-block;
  height: var(--radiocheck-size-inner);
  width: var(--radiocheck-size-inner);
  background: radial-gradient(white, var(--beta5) 30%);
  border: var(--radiocheck-size-inner-border) solid var(--beta5);
}

input[type=radio]:checked + label::before, input[type=checkbox]:checked + label::before {
  /*background: #0F0;*/
  /*border-color: var(--beta9);*/
}

[type=radio] + label::before, [type=radio] + label::after, [type=checkbox] + label::before, [type=checkbox] + label::after {
  position: absolute;
  left: 0;
  top: 0;
}

[type=radio] + label::after, [type=checkbox] + label::after {
  left: calc(var(--radiocheck-size-difference) / 2);
  top: calc(var(--radiocheck-size-difference) / 2);
}

input[type=radio] + label::after, input[type=checkbox] + label::after {
  content: none;
}

input[type=radio]:checked + label::after, input[type=checkbox]:checked + label::after {
  content: "";
}

input:focus, input[type=radio]:focus + label::before, input[type=checkbox]:focus + label::before {
  /*background: rgba(0, 0, 0, .1);*/
}

:disabled, [type=radio]:disabled + label::before, [type=checkbox]:disabled + label::before {
  opacity: 0.3;
}

.labelitem {
  white-space: nowrap;
}

form button {
  margin: 0; /* Change this on teatroterapia.css */
}

.error {
  background: red;
  border-radius: 0.5em;
  color: white;
  padding: 0.1em 0.5em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
}

.error + * {
  margin-top: 1.5em;
}

.error + .buttoncontainer, .error + .error {
  margin-top: 0.5em;
}

.nonaria-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.pageloader {
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pageloader__notification-box {
  background-color: var(--alpha5);
  border-radius: 10px;
  padding: 1em;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pageloader__img {
  max-width: 200px;
}
.pageloader__img--submitted {
  animation-name: rotateY;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.pageloader__img--error {
  animation: rotateZ 2s forwards;
}
.pageloader__img2 {
  position: absolute;
  max-width: 200px;
  animation-name: rotateY2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes rotateY2 {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(450deg);
  }
}
@keyframes rotateZ {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
.gallery__lightbox {
  margin-left: calc(-1 * var(--innerthorax-padding-horizontal));
  margin-right: calc(-1 * var(--innerthorax-padding-horizontal));
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.gallery__arrow {
  position: absolute;
  color: var(--alpha5);
  margin-left: 0;
  font-size: calc(40px + 0.05 * var(--innerthorax-width));
  opacity: 0.05;
}
.gallery__arrow:hover {
  opacity: 0.9;
  cursor: pointer;
}
.gallery__arrow:active {
  color: var(--alpha3);
}
.gallery__arrow--left {
  left: 2vw;
}
.gallery__arrow--right {
  right: 2vw;
}
.gallery__indicator-container {
  position: absolute;
  bottom: 2vw;
}
.gallery__indicator {
  background: var(--beta5);
  height: calc(0.05 * var(--innerthorax-width));
  width: calc(0.05 * var(--innerthorax-width));
  max-width: 20px;
  max-height: 20px;
  padding: 0;
  border-radius: 50%;
}
.gallery__indicator:hover {
  cursor: pointer;
}
.gallery__indicator--selected {
  background: var(--alpha5);
}
.gallery ul {
  position: relative;
  margin: 0;
  list-style: none;
}
.gallery li {
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.gallery li.hidden {
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.recommendation {
  margin: 2em var(--innerthorax-padding-horizontal);
}
.recommendation__lightbox {
  position: relative;
  padding-bottom: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.recommendation__outer-container {
  height: 10em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.recommendation__inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.recommendation__quote {
  font-size: calc(20px + 7 * (100vw - 300px) / 620);
}
.recommendation__source {
  text-align: right;
}
.recommendation__footnote {
  font-size: 0.7em;
  font-style: italic;
  text-align: right;
}
.recommendation__arrow {
  position: absolute;
  color: var(--alpha5);
  margin-left: 0;
  font-size: calc(40px + 0.05 * var(--innerthorax-width));
  opacity: 0.05;
}
.recommendation__arrow:hover {
  opacity: 0.9;
  cursor: pointer;
}
.recommendation__arrow:active {
  color: var(--alpha3);
}
.recommendation__arrow--left {
  left: 2vw;
}
.recommendation__arrow--right {
  right: 2vw;
}
.recommendation__indicator-container {
  position: absolute;
  bottom: 0;
}
.recommendation__indicator {
  background: var(--beta5);
  height: calc(0.05 * var(--innerthorax-width));
  width: calc(0.05 * var(--innerthorax-width));
  max-width: 20px;
  max-height: 20px;
  padding: 0;
  border-radius: 50%;
}
.recommendation__indicator:hover {
  cursor: pointer;
}
.recommendation__indicator--selected {
  background: var(--alpha5);
}
.recommendation ul {
  width: 100%;
  position: relative;
  margin: 0;
  list-style: none;
}
@media all and (min-width: 500px) {
  .recommendation ul {
    width: 80%;
  }
}
.recommendation li {
  height: 100%;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.recommendation li.hidden {
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 1em 0;
}
.card__card {
  background: white;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 5px 5px 30px black;
}
.card__card:hover {
  text-decoration: none;
  box-shadow: 0 0 15px 2px var(--alpha5);
}
@media all and (min-width: 700px) {
  .card__card {
    width: 45%;
  }
  .card__card .flier {
    height: calc(0.2781 * var(--innerthorax-width));
  }
}
.card__img {
  width: 100%;
  object-fit: contain;
}
.card__title {
  background: white;
  color: black;
  text-align: center;
  font-family: Euphori, cursive;
  font-weight: bold;
}
.card__detail {
  background: white;
  color: black;
  text-align: center;
  font-size: 0.75em;
}

.contactodirecto {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  padding: 0.5em;
  display: grid;
  justify-content: center;
  grid-template-columns: auto 50px repeat(2, auto);
  grid-template-areas: "contactologo . contactoname contactoname" "contactologo contactophones contactophone1 contactophone1" "contactologo contactophones contactophone2 contactophone2" "contactologo contactoemails contactoemail contactoemail";
  align-items: center;
}
.contactodirecto-container {
  display: flex;
  justify-content: center;
}
.contactodirecto i + * {
  margin-left: 0;
}
.contactodirecto__logo {
  grid-area: contactologo;
  height: inherit;
  justify-self: end;
}
.contactodirecto__name {
  grid-area: contactoname;
  font-family: "Catamaran", sans-serif;
  font-size: 1.3em;
  color: hsl(30, 100%, 50%);
  text-shadow: 0px 0px 8px black;
  font-weight: bold;
}
.contactodirecto__phones {
  grid-area: contactophones;
}
.contactodirecto__phone1 {
  grid-area: contactophone1;
}
.contactodirecto__phone2 {
  grid-area: contactophone2;
}
.contactodirecto__emails {
  grid-area: contactoemails;
}
.contactodirecto__email {
  grid-area: contactoemail;
}

.quote {
  color: var(--alpha5);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.quote blockquote {
  width: 80%;
}

blockquote {
  background: rgba(255, 255, 255, 0.75);
  color: var(--alpha5);
  text-shadow: 0.5px 0.5px var(--alpha3);
  font-family: var(--signature-font);
  font-size: 1.4rem;
}

blockquote::before {
  content: '"';
  display: inline;
}

blockquote::after {
  content: '"';
  display: inline;
}

img.left {
  float: left;
  margin-right: 1em;
}

img.right {
  float: right;
  margin-left: 1em;
}

@media all and (min-width: 920px) {
  :root {
    --contents-width: 920px;
  }
  h2 {
    font-size: 30px;
  }
  img.half {
    width: calc(0.5 * var(--innerthorax-width));
    height: auto;
  }
}
:root {
  --title-width: 100%;
  --titlecontents-width: var(--contents-width);
}
h1 {
  text-align: center;
  color: var(--alpha5);
  font-family: var(--signature-font);
  font-size: calc(.14 * var(--titlecontents-width)); /*Changed in psico*/
}

.title {
  margin-top: 2em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: calc(.33 * var(--titlecontents-width));
  }

  &__subtitle {
    color: var(--beta5);
    font-size: calc(.05 * var(--titlecontents-width));
  }
}

@media all and (min-width: 800px) {
  :root {
    --titlecontents-width: 700px;
  }
}

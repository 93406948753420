.contactodirecto {
  &-container {
    display: flex;
    justify-content: center;
  }
  background: rgba(white, .95);
  border-radius: 10px;
  padding: .5em;
  display: grid;
  justify-content: center;
  grid-template-columns: auto 50px repeat(2, auto);
  grid-template-areas:
    "contactologo . contactoname contactoname"
    "contactologo contactophones contactophone1 contactophone1"
    "contactologo contactophones contactophone2 contactophone2"
    "contactologo contactoemails contactoemail contactoemail";
  align-items: center;

  i+* {
    margin-left: 0;
  }

  &__logo {
    grid-area: contactologo;
    height: inherit;
    // max-width: 10vw;
    // object-fit: cover;
    justify-self: end;
    // margin-right: 1em;
  }

  &__name {
    grid-area: contactoname;
    font-family: 'Catamaran', sans-serif;
    font-size: 1.3em;
    color: hsla(30, 100%, 50%, 1);
    text-shadow: 0px 0px 8px black;
    font-weight: bold;
  }
  &__phones {
    grid-area: contactophones;
  }
  &__phone {
    &1 {
      grid-area: contactophone1;
    }
    &2 {
      grid-area: contactophone2;
    }
  }
  &__emails {
    grid-area: contactoemails;
  }
  &__email {
    grid-area: contactoemail;
  }
}

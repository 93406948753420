form {
  --radiocheck-size-outer: 18px;
	--radiocheck-size-outer-border: 1px;
	--radiocheck-size-inner: 14px;
	--radiocheck-size-inner-border: 0px;
	--radiocheck-size-difference: calc((var(--radiocheck-size-outer) + 2 * var(--radiocheck-size-outer-border)) - (var(--radiocheck-size-inner) + 2 * var(--radiocheck-size-inner-border)));
  line-height: 1;
  // box-sizing: border-box;

  // em {
  //   color: var(--alpha5);
  // }
  //
  // a {
  //   color: var(--alpha5);
  // }

  section {
  	display: flex;
  	margin: .5em 0;
  }
  section > :first-child {
    background: var(--beta5);
  	color: rgba(255, 255, 255, .75);
  	border-radius: 10px 0 0 10px;
  	display: flex;
    justify-content: center;
    align-items: center;
  	min-width: 2em;
  }
  section > :last-child {
    background: rgba(255, 255, 255, .75);
  	border-radius: 0 10px 10px 0;
  	padding: 1em;
    flex-grow: 1;
  	border: solid 1px var(--beta5);
  }

  .row {
  	display: flex;
    align-items: center;
  	/*flex-wrap: wrap;*/
  }
  .row + .row {
  	margin-top: 1em;
  }
  .row.textarea {
    flex-direction: column;
    align-items: flex-start;
  }
  .row.textarea textarea {
  	// margin-left: 0;
  }
  .row.space-horizontally {
    justify-content: space-around;
  }

  .footnote {
  	border-top: darkgray solid 1px;
  	font-size: .8em;
  	margin-top: 1em;
  }

  .button {

    &container--form {
      justify-content: flex-end;
    }
  }
}


label+*, *+label { /* Change this on teatroterapia.css*/
	margin-left: .5em;
}

fieldset {
	border: 0;
}
fieldset + fieldset {
	margin-top: 2em;
}
input, textarea, select, button {
	background: var(--beta9);
	border: 0;
	outline: 0;
	resize: vertical;
	font-size: var(--font-size);
	border-radius: 5px;
  padding-left: .5em;
}
textarea {
	height: 7em;
	margin-left: 0;
  align-self: stretch;
}
button {
  border: initial;
}


/* Radio and Checkbox Inputs */

input[type="radio"], input[type="checkbox"] {
	opacity: 0.1;
	width: 0;
}
[type="radio"]+label, [type="checkbox"]+label {
	position: relative;
	padding-left: calc(var(--radiocheck-size-outer) + .5em);
	text-align: left;
}
[type="radio"]+label::before, [type="checkbox"]+label::before {
	content: '';
	text-align: center;
	display: inline-block;
	height: var(--radiocheck-size-outer);
	width: var(--radiocheck-size-outer);
	background: var(--beta9);
	border: var(--radiocheck-size-outer-border) solid var(--beta9);
}
[type="radio"]+label::before, [type="radio"]+label::after {
	border-radius: 50%;
}
[type="checkbox"]+label::before, [type="checkbox"]+label::after {
	border-radius: 0;
}
[type="radio"]+label::after, [type="checkbox"]+label::after {
	content: "";
	display: inline-block;
	height: var(--radiocheck-size-inner);
	width: var(--radiocheck-size-inner);
	background: radial-gradient(white, var(--beta5) 30%);
	border: var(--radiocheck-size-inner-border) solid var(--beta5);
}
input[type="radio"]:checked+label::before, input[type="checkbox"]:checked+label::before {
	/*background: #0F0;*/
	/*border-color: var(--beta9);*/
}
[type="radio"]+label::before, [type="radio"]+label::after, [type="checkbox"]+label::before, [type="checkbox"]+label::after {
	position: absolute;
	left: 0;
	top: 0;
}
[type="radio"]+label::after, [type="checkbox"]+label::after {
	left: calc(var(--radiocheck-size-difference) / 2);
	top: calc(var(--radiocheck-size-difference) / 2);
}

input[type="radio"]+label::after, input[type="checkbox"]+label::after {
	content: none;
}
input[type="radio"]:checked+label::after, input[type="checkbox"]:checked+label::after {
	content: "";
}
input:focus, input[type="radio"]:focus+label::before, input[type="checkbox"]:focus+label::before {
	/*background: rgba(0, 0, 0, .1);*/
}
:disabled, [type="radio"]:disabled+label::before, [type="checkbox"]:disabled+label::before {
	opacity: 0.3;
}

.labelitem {
	white-space: nowrap;
}




form button {
	margin: 0; /* Change this on teatroterapia.css */
}
.error {
	background: red;
	border-radius: .5em;
	color: white;
	padding: .1em .5em;
	margin-top: .5em;
  display: flex;
  align-items: center;
}
.error+* {
	margin-top: 1.5em;
}
.error+.buttoncontainer, .error+.error {
	margin-top: .5em;
}

.nonaria-hidden {
	position: absolute;
	top: -9999px;
	left: -9999px;
}

.recommendation {
  margin: 2em var(--innerthorax-padding-horizontal);

  &__lightbox {
    position: relative;
    padding-bottom: 1.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  &__outer-container {
    height: 10em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &__inner-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &__quote {
    // color: var(--beta5);
    font-size: calc(20px + (32 - 25) * (100vw - 300px) / (920 - 300));
    // font-size: 20px;
  }

  &__source {
    text-align: right;
  }

  &__footnote {
    font-size: .7em;
    font-style: italic;
    text-align: right;
  }

  &__arrow {
    position: absolute;
    color: var(--alpha5);
    margin-left: 0;
    font-size: calc(40px + .05 * var(--innerthorax-width));
    opacity: .05;
    &:hover {
      opacity: .9;
      cursor: pointer;
    }
    &:active {
      color: var(--alpha3);
    }

    &--left {
      left: 2vw;
    }
    &--right {
      right: 2vw;
    }
  }

  &__indicator-container {
    position: absolute;
    bottom: 0;
  }
  &__indicator {
    background: var(--beta5);
    height: calc(.05 * var(--innerthorax-width));
    width: calc(.05 * var(--innerthorax-width));
    max-width: 20px;
    max-height: 20px;
    padding: 0;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
    &--selected {
      background: var(--alpha5);
    }
  }


  ul {
    width: 100%;
    position: relative;
    margin: 0;
    list-style: none;
    @media all and (min-width: 500px) {
        width: 80%;
    }
  }
  li {
  height: 100%;
    position: absolute;
    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }
  li.hidden {
    bottom: 0;
    opacity: 0;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
  }
}

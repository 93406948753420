.card {
  &-container {
    // background: white;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 1em 0;
  }
  &__card {
    background: white;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 5px 5px 30px black;
    &:hover {
      text-decoration: none;
      box-shadow: 0 0 15px 2px var(--alpha5);
    }
    @media all and (min-width: 700px) {
      width: 45%;
      .flier {
        height: calc(0.618 * 0.45 * var(--innerthorax-width));
      }
    }
  }
  &__img {
    width: 100%;
    object-fit: contain;
  }
  &__title {
    background: white;
    color: black;
    text-align: center;
    font-family: Euphori, cursive;
    font-weight: bold;
  }
  &__detail {
    background: white;
    color: black;
    text-align: center;
    font-size: .75em;
  }
}

#nav-languages {
  width: var(--innerthorax-width);
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  display: flex;
  justify-content: flex-end;

  li {
    background: var(--alpha5);
    border: .5px solid gray;
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // flex-grow: 1;
    &:hover {
      background: var(--alpha3);
      box-shadow: 0 0 15px 2px var(--beta5);
    }
  }
  a {
    display: block;
    color: white;
    padding: .5em .3em;

    &:hover {
      text-decoration: none;
    }
  }
}

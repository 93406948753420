:root {
  --alpha9: ;
  --alpha8: ;
  --alpha7: ;
  --alpha6: ;
  --alpha5: hsla(30, 100%, 50%, 1);
  --alpha4: ;
  --alpha3: hsla(30, 100%, 40%, 1);
  --alpha2: ;
  --alpha1: hsla(30, 100%, 30%, 1);
  --beta9: hsla(120, 100%, 90%, 1);
  // --beta9: hsla(180, 20%, 85%, 1);
  --beta8: ;
  --beta7: ;
  --beta6: ;
  --beta5: hsla(120, 100%, 20%, 1);
  // --beta5: hsla(180, 20%, 20%, 1);
  --beta4: ;
  --beta3: hsla(120, 100%, 5%, 1);
  --beta2: ;
  --beta1: ;
  --background-image: url('/img/transparent-orange100.svg');
  --signature-font: 'Catamaran', sans-serif;
  --contents-width: calc(100vw - 17px);
  --thorax-width: 100vw;
  --innerthorax-width: var(--contents-width);
  --innerthorax-padding-horizontal: calc(.04 * var(--innerthorax-width));
  font-size: calc(10pt + .01 * var(--innerthorax-width));
}
// @font-face {
// 	font-family: "Euphori";
//    src: url("/fonts/euphori.eot?#iefix");
//    src: url("/fonts/euphori.eot?#iefix") format("eot"),url("/fonts/euphori.woff2") format("woff2"),url("/fonts/euphori.woff") format("woff"),url("/fonts/euphori.ttf") format("truetype");
// }
*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  text-align: justify;
  font-family: 'KoHo', sans-serif;
}

//Generic elements
h2, h3, h4, h5, h6 {
  color: var(--beta5);
  margin-top: 2em;
  margin-bottom: .5em;
  text-align: left;
}
h2 {
  font-size: calc(18px + (30 - 18) * (100vw - 300px) / (920 - 300));
  &.center {
    text-align: center;
  }
}

a {
  text-decoration: none;
  color: var(--beta5);

  &:hover {
    text-decoration: underline;
  }
}

p+p,
p+img, img+p,
p+picture, picture+p,
p+.imgcontainer, .imgcontainer+p,
section+*, *+section {
  margin-top: 1em;
}
section:first-of-type {
  margin-top: 1em;
}

i+*, *+i {
  margin-left: .5em;
}


// Buttons
.buttoncontainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.btn {
  background: var(--beta5);
  color: white;
  padding: .1em .5em;
  cursor: pointer;
  // border-color: var(--beta9) var(--beta5) var(--beta5) var(--beta9);
  // border-style: solid;
  // border-width: 2px;

  &__icon {
    color: rgba(255, 255, 255, .75);
  }
  &:hover {
    box-shadow: 0 0 15px 2px var(--beta5);
  }
  &:active {
    background: var(--beta3);
  }
}

// Images and Embedded maps
.imgcontainer {
  display: flex;
  justify-content: center;
}

img {
  width: 100%;
}
.map, section > .imgcontainer {
  border: 0;
  margin-left: calc(-1 * var(--innerthorax-padding-horizontal));
  margin-right: calc(-1 * var(--innerthorax-padding-horizontal));
  width: var(--innerthorax-width);
  height: calc(.618 * var(--innerthorax-width));
}

.lp__title {
  margin-top: 1em;
}






.thorax {
  background-image: var(--background-image);
  background-size: contain;
  margin-top: 2em;
  width: 100%;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innerthorax {
  background: white;
  background-image: linear-gradient(rgba(255, 255, 255, .9),
                                    rgba(255, 255, 255, .8)),
                    var(--background-image);
  background-size: contain;
  width: var(--innerthorax-width);
  margin-bottom: 2em;
  //min-height: 100vh;

  // ul {
  //   padding-left: 1em;
  // }


  & > img {
    margin: 0;
  }
  & > img+*, & > *+img {
    margin-top: 2em;
  }

  & > *, & ul {
      margin-left: var(--innerthorax-padding-horizontal);
      margin-right: var(--innerthorax-padding-horizontal);
  }
  ul {
    margin-bottom: 1em;
  }


}
@import 'title';
@import 'languagebar';
@import 'navigation';
@import 'footer';
@import 'actnow';
@import 'form';
@import 'pageloader';
@import 'gallery';
@import 'recommendation';
@import 'embeds';

@import 'contactodirecto';

//Page specific
// .contacto {
//   display: flex;
//   justify-content: center;
//
//   &__logo {
//     margin-right: 1em;
//     img {
//       height: 100%;
//       width: auto;
//     }
//   }
//
//   &__details {
//     display: flex;
//     flex-direction: column;
//     justify-content:space-around;
//   }
//
//   &__name {
//     font-family: var(--signature-font);
//     font-size: 1.3em;
//     color: var(--alpha5);
//     font-weight: bold;
//   }
// }

.quote {
  color: var(--alpha5);
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  blockquote {
    width: 80%;
  }
}
blockquote {
  background: rgba(white, .75);
  color: var(--alpha5);
  text-shadow: .5px .5px var(--alpha3);
  font-family: var(--signature-font);
  font-size: 1.4rem;
  // font-size: calc(.033 * var(--innerthorax-width));
  // font-size: calc(17px + (25 - 17) * (100vw - 270px) / (920 - 270));
  // font-size: 17px;
}
blockquote::before {
  content: '"';
  display: inline;
}
blockquote::after {
  content: '"';
  display: inline;
}


img.left {
  float: left;
  margin-right: 1em;
}
img.right {
  float: right;
  margin-left: 1em;
}

@media all and (min-width: 500px) {

}

@media all and (min-width: 920px) {
  :root {
    --contents-width: 920px;
  }
  h2 {
    font-size: 30px;
  }
  img.half {
    width: calc(.5 * var(--innerthorax-width));
    height: auto;
  }
}
